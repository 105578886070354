import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { SquareButton } from '../../components/bulma/button.styled'
import { CardFooter } from '../../components/bulma/card.styled'
import { Field, FieldBody, FieldLabel, Label } from '../../components/bulma/field.styled'
import { CardHeader, CardHeaderTitle, ScrollableCard, ScrollableCardContent } from '../../components/card.styled'
import { CountryDropdown } from '../../components/dropdown/country-dropdown'
import { notification } from '../../lib/notification'
import { TerminalCountryCode } from '../../types/terminal-country-codes'
import { WebapiRole } from '../../types/webapi-role'
import { useCreateCollectionPlacePoints } from '../../webapi/hooks/mutations/use-create-collection-place-point'
import { useSaveCollectionPlacePoints } from '../../webapi/hooks/mutations/use-save-collection-place-point'
import { useHasAnyWebapiRole } from '../../webapi/hooks/use-has-any-webapi-role'
import { MerchantWarehouse } from '../../webapi/types/merchant-warehouses'

import { CsvInput } from './csv-input'
import { MerchantWarehouseDropdown } from './merchant-warehouse-dropdown'
import { FieldRow, Input, NumberInput } from './warehouse.styled'

interface WarehouseProps {
  warehouse: MerchantWarehouse
  onClose: () => void
}
export const Warehouse = (props: WarehouseProps) => {
  const { warehouse, onClose } = props
  const query = useQueryClient()

  const hasEditPermission = useHasAnyWebapiRole(WebapiRole.OperationsAdministrator)
  const [editing, setEditing] = useState<boolean>(!warehouse.identifier)
  const [name, setName] = useState(warehouse.name)
  const [street, setStreet] = useState(warehouse.street)
  const [city, setCity] = useState(warehouse.city)
  const [postalCode, setPostalCode] = useState(warehouse.postalCode)
  const [latitude, setLatitude] = useState<number | string>(warehouse.latitude)
  const [longitude, setLongitude] = useState<number | string>(warehouse.longitude)
  const [country, setCountry] = useState<TerminalCountryCode>(warehouse.countryCode)
  const [collectionPoints, setCollectionPoints] = useState<number[]>(warehouse.collectionPointIds)
  const [places, setPlaces] = useState<string[]>(warehouse.placeIds)

  const refreshWarehouse = useCallback((data: MerchantWarehouse) => {
    setEditing(!data.identifier)
    setName(data.name)
    setStreet(data.street)
    setCity(data.city)
    setPostalCode(data.postalCode)
    setLatitude(data.latitude)
    setLongitude(data.longitude)
    setCountry(data.countryCode)
    setCollectionPoints(data.collectionPointIds)
    setPlaces(data.placeIds)
  }, [])
  useEffect(() => {
    refreshWarehouse(warehouse)
  }, [warehouse, refreshWarehouse])

  const save = useMutation({
    ...useSaveCollectionPlacePoints(),
    onSuccess: () => {
      notification.success('Merchant Warehouse Saved')
      query.invalidateQueries(['collection-place-points'])
    },
    onError: () => {
      notification.error('Failed to save collection place points')
    },
  })

  const create = useMutation({
    ...useCreateCollectionPlacePoints(),
    onSuccess: () => {
      notification.success('Merchant Warehouse Created')
      query.invalidateQueries(['collection-place-points'])
    },
    onError: () => {
      notification.error('Failed to create collection place point')
    },
  })

  const sanitizeCoordinateInput = (numberInput: string) => {
    // since we use NumberInput, no need to verify that the input has correct symbols
    return numberInput.length ? Number(numberInput) : undefined
  }

  const validateCoordinates = () => {
    return latitude !== undefined && longitude !== undefined
  }

  const hasAllRequiredData = name && street && postalCode && city && country && latitude && longitude

  const onPersist = () => {
    if (!validateCoordinates()) {
      return
    }
    const body = {
      name,
      street,
      postalCode,
      city,
      countryCode: country,
      latitude: Number(latitude),
      longitude: Number(longitude),
      collectionPointIds: collectionPoints,
      placeIds: places,
    }
    if (warehouse.identifier) {
      save.mutate({
        identifier: warehouse.identifier,
        body,
      })
    } else {
      create.mutate({ body })
    }
  }
  const isPersisting = save.isLoading || create.isLoading
  return (
    <ScrollableCard>
      <ScrollableCardContent>
        <CardHeader>
          <CardHeaderTitle>Warehouse</CardHeaderTitle>
          {hasEditPermission && (
            <div style={{ float: 'right' }}>
              <MerchantWarehouseDropdown warehouse={warehouse} onDelete={onClose} />
            </div>
          )}
        </CardHeader>
        <div style={{ height: 15 }}></div>
        <Field isHorizontal>
          <FieldLabel>
            <Label>Name</Label>
          </FieldLabel>
          <FieldBody>
            <Input value={name} onChange={(e) => setName(e.target.value)} readOnly={!editing} />
          </FieldBody>
        </Field>
        <hr />
        <Field>
          <FieldLabel>
            <Label>Street</Label>
          </FieldLabel>
          <FieldBody>
            <Input value={street} onChange={(e) => setStreet(e.target.value)} readOnly={!editing} />
          </FieldBody>
        </Field>
        <FieldRow>
          <Field isStretched>
            <FieldLabel>
              <Label>Postal Code</Label>
            </FieldLabel>
            <FieldBody>
              <Input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} readOnly={!editing} />
            </FieldBody>
          </Field>
          <Field isStretched>
            <FieldLabel>
              <Label>City</Label>
            </FieldLabel>
            <FieldBody>
              <Input value={city} onChange={(e) => setCity(e.target.value)} readOnly={!editing} />
            </FieldBody>
          </Field>
        </FieldRow>
        <Field isStretched>
          <FieldLabel>
            <Label>Country</Label>
          </FieldLabel>
          <FieldBody>
            <CountryDropdown readonly={!editing} selected={country} onChange={(value) => setCountry(value)} />
          </FieldBody>
        </Field>
        <FieldRow>
          <Field isStretched>
            <FieldLabel>
              <Label>Latitude</Label>
            </FieldLabel>
            <FieldBody>
              <NumberInput
                value={latitude}
                onChange={(e) => setLatitude(sanitizeCoordinateInput(e.target.value) || '')}
                readOnly={!editing}
              />
            </FieldBody>
          </Field>
          <Field isStretched>
            <FieldLabel>
              <Label>Longitude</Label>
            </FieldLabel>
            <FieldBody>
              <NumberInput
                value={longitude}
                onChange={(e) => setLongitude(sanitizeCoordinateInput(e.target.value) || '')}
                readOnly={!editing}
              />
            </FieldBody>
          </Field>
        </FieldRow>
        <hr />
        <Field isHorizontal>
          <FieldLabel>
            <Label>Collection Points</Label>
          </FieldLabel>
          <FieldBody>
            <CsvInput
              readonly={!editing}
              values={collectionPoints.map((val) => val.toString())}
              onChange={(values) => setCollectionPoints(values.map((val) => parseInt(val)))}
              isValidValue={(value) => /^\d+$/.test(value)}
            />
          </FieldBody>
        </Field>
        <Field isHorizontal>
          <FieldLabel>
            <Label>Places</Label>
          </FieldLabel>
          <FieldBody>
            <CsvInput
              readonly={!editing}
              values={places}
              onChange={(values) => setPlaces(values)}
              isValidValue={(value) => /^[A-Za-z0-9_\\-]*$/.test(value)}
            />
          </FieldBody>
        </Field>
      </ScrollableCardContent>
      {editing ? (
        <EditFooter
          onDiscard={() => (warehouse.identifier ? setEditing(false) : onClose())}
          persistVerb={warehouse.identifier ? 'Save' : 'Create'}
          disablePersist={!hasAllRequiredData || isPersisting}
          onPersist={onPersist}
        />
      ) : (
        <ViewFooter onEdit={() => setEditing(true)} onClose={onClose} editable={hasEditPermission} />
      )}
    </ScrollableCard>
  )
}

interface EditFooterProps {
  onDiscard: () => void
  persistVerb: string
  disablePersist: boolean
  onPersist: () => void
}
const EditFooter = ({ onDiscard, persistVerb, onPersist, disablePersist }: EditFooterProps) => {
  return (
    <CardFooter>
      <div style={{ flex: 1 }}></div>
      <SquareButton isLight isRounded={false} onClick={onDiscard}>
        Discard
      </SquareButton>
      <SquareButton isPrimary isRounded={false} onClick={onPersist} disabled={disablePersist}>
        {persistVerb}
      </SquareButton>
    </CardFooter>
  )
}

interface ViewFooterProps {
  onEdit: () => void
  onClose: () => void
  editable: boolean
}
const ViewFooter = ({ onEdit, onClose, editable }: ViewFooterProps) => {
  return (
    <CardFooter>
      <div style={{ flex: 1 }}></div>
      <SquareButton isLight isRounded={false} onClick={onClose}>
        Close
      </SquareButton>
      {editable && (
        <SquareButton isInfo isRounded={false} onClick={onEdit}>
          Edit
        </SquareButton>
      )}
    </CardFooter>
  )
}
