import { faAngleDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import React, { useCallback, useRef, useState } from 'react'

import { Button } from '../../components/bulma/button.styled'
import { Icon } from '../../components/select/select.styled'
import { useClickOutside } from '../../hooks/use-click-outside'

import {
  ValueItem,
  Menu,
  MenuContent,
  AddValueContainer,
  AddValueInputContainer,
  AddValueButtonContainer,
  AddValueButton,
  AddValueInput,
  CsvContainer,
  CsvTrigger,
} from './csv.styled'

interface CsvInputProps {
  readonly: boolean
  values: string[]
  onChange: (values: string[]) => void
  isValidValue: (value: string) => boolean
}
export const CsvInput = (props: CsvInputProps) => {
  const { readonly, values, onChange, isValidValue } = props
  const [newValue, setNewValue] = useState<string>('')
  const [active, setActive] = useState<boolean>(false)

  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const onClickOutside = useCallback(() => setActive(false), [])
  useClickOutside(dropdownMenuRef, onClickOutside, active)

  const onAdd = () => {
    if (!values.includes(newValue)) {
      onChange([...values, newValue])
      setNewValue('')
    }
  }
  const onRemove = (value: string) => {
    onChange(values.filter((val) => val !== value))
    setNewValue('')
  }
  const renderItem = (value: string) => {
    return (
      <ValueItem key={value}>
        <span>{value}</span>
        <span className="icon is-small" onClick={() => onRemove(value)}>
          <Icon icon={faXmark} />
        </span>
      </ValueItem>
    )
  }
  const readOnlyTrigger = <input className="input is-small" value={values.join(', ')} readOnly={true} />
  const editTrigger = (
    <Button
      isSmall
      aria-haspopup="true"
      aria-controls="dropdown-menu"
      onClick={() => !readonly && setActive(!active)}
      style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}
    >
      <span>{values.join(', ')}</span>
      {!readonly && (
        <span className="icon is-small">
          <Icon icon={faAngleDown} />
        </span>
      )}
    </Button>
  )
  return (
    <CsvContainer active={active} ref={dropdownMenuRef}>
      <CsvTrigger>{readonly ? readOnlyTrigger : editTrigger}</CsvTrigger>
      <Menu>
        <MenuContent>
          {values.map(renderItem)}
          <AddValueContainer>
            <AddValueInputContainer>
              <AddValueInput value={newValue} onChange={(e) => setNewValue(e.target.value)} />
            </AddValueInputContainer>
            <AddValueButtonContainer>
              <AddValueButton onClick={onAdd} disabled={newValue.length === 0 || !isValidValue(newValue)}>
                Add
              </AddValueButton>
            </AddValueButtonContainer>
          </AddValueContainer>
        </MenuContent>
      </Menu>
    </CsvContainer>
  )
}
